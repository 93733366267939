<template>
  <div class="busi_wrap">
    <div class="tit_1 top">리뷰앤메이크머니와 사업하기</div>

    <div class="tit_2" id="tab1">
      협업의 기본구조<router-link to="" @click.native="openTab($event, 1)"
        >∧</router-link
      >
    </div>
    <div class="busi_sec_1" id="tab1_detail">
      <div class="txt">
        램의 비즈니스 모델을 이해하고 함께하기를 원하는 기업은 모두 램에 제휴사
        신청을 할 수 있습니다. 제휴사와 램, 리뷰어의 협업 구조는 다음과
        같습니다.
      </div>
      <div class="img">
        <img src="@/assets/images/sub/business_01.jpg" alt="" />
      </div>
      <div class="txt">
        ① 제휴사(램 입점 업체 포함)는 램에 상품 및 서비스 정보(URL)를 제공한다.
        ② 리뷰어는 램에 제공된 상품이나 서비스 정보 중에서 원하는 것을 취사
        선택한다. ③ 리뷰어는 자신이 선택한 상품이나 서비스에 대해 정확하고
        정직한 리뷰를 램에 남긴다. 이때 그 상품이나 서비스의 URL 역시 구매
        버튼으로 생성된다. ④ 소비자들은 이렇게 생성된 리뷰들 중 신뢰할 만한
        리뷰의 구매 버튼을 누르고 ⑤ 이는 제휴사(또는 램 가게)로 연결되며
        소비자들은 제휴사(또는 램 가게)에서 상품이나 서비스를 구매한다. ⑥ 이로
        인해 제휴사(또는 램 가게)에 수익이 발생하고 ⑦ 제휴사 (또는 램 가게)는
        램에 판매 건당 인프라 사용료를 지급하며 ⑧ 램은 이 중 일부를 리뷰어에게
        리뷰 보상 포인트로 지급한다.
      </div>
    </div>

    <div class="tit_2" id="tab2">
      제휴사 신청 안내<router-link to="" @click.native="openTab($event, 2)"
        >∧</router-link
      >
    </div>
    <div class="busi_sec_2" id="tab2_detail">
      <p>
        판매자로 램 가게에 입점하실 분은 제휴사 신청을 하실 필요 없습니다. 램에
        일반회원으로 가입하신 후 [판매자 채널 신청하기]를 통해 램 가게에
        입점하세요.
      </p>
      <p>
        제휴사 신청 과정은 [신청서 등록 접수 ➞ 본사의 검토 ➞ 양사 협의 ➞ 최종
        결정]입니다.
      </p>
      <p>
        모든 과정은 이메일로 진행되며, [양사 협의] 과정에서 전화나 대면 협의가
        가능합니다.
      </p>
      <p>
        [신청서 등록]은 본사의 양식에 따라 진행됩니다. 아래 [제휴사 신청 등록]
        버튼을 눌러 절차를 밟아주세요.
      </p>
      <p>
        신청서가 접수되면, 접수일로부터 7일간 [본사의 검토] 과정을 거쳐 그
        결과가 신청서에 입력하신 이메일 또는 전화로 전달됩니다.
      </p>
      <p>
        신청서 작성 과정에서 귀사의 영업비밀이나 기밀 사항은 제외하고 공개
        가능한 내용만 기록해 주시기 바랍니다. 귀사의 지식재산권 역시 권리 등록이
        완료된 것만을 공개 바랍니다.
      </p>
      <p>
        관련 자료는 [제휴 검토]를 목적으로만 사용되며, 제휴 불가가 결정되면
        파기됩니다.
      </p>
    </div>

    <div class="tit_2">
      제휴사 신청 등록<router-link
        to=""
        @click.native="$toast.default('준비중 입니다.')"
        >∨</router-link
      >
    </div>
    <!-- <div class="busi_sec_3">
      <div class="busi_in_box">
        <div class="tit_2">제휴 제안사</div>
        <ul>
          <li>
            <input
              type="text"
              name=""
              placeholder="회사 이름을 적어주세요."
              class="w_max"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              placeholder="담당자 이름을 적어주세요."
              class="w_max"
            />
          </li>
          <li class="add">
            <input
              type="text"
              name=""
              placeholder="회사소개서를 첨부해주세요."
            /><a href="#" class="t_1">파일찾기</a
            ><a href="#" class="t_2">파일삭제 <font color="red">X</font></a>
          </li>
        </ul>
      </div>
      <div class="busi_in_box">
        <div class="tit_2">연락처</div>
        <ul>
          <li>
            <input
              type="text"
              name=""
              placeholder="이메일 주소를 적어주세요."
              class="w_max"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              placeholder="전화번호를 적어주세요."
              class="w_max"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              placeholder="회사 홈페이지 주소를 적어주세요(없으면 생략)."
              class="w_max"
            />
          </li>
        </ul>
      </div>
      <div class="busi_cate_box">
        <div class="tit_2">
          제휴 분야<span>(아래에서 카테고리를 선택해주세요.)</span>
        </div>
        <ul>
          <li><a href="">상품</a></li>
          <li class="on"><a href="">책/음반</a></li>
          <li><a href="">뉴스</a></li>
          <li><a href="">영화</a></li>
          <li><a href="">음악</a></li>
          <li><a href="">학원</a></li>
          <li><a href="">병원</a></li>
          <li><a href="">헬스</a></li>
          <li><a href="">뷰티</a></li>
          <li><a href="">보험</a></li>
          <li><a href="">금융</a></li>
          <li><a href="">증권</a></li>
          <li><a href="">부동산</a></li>
          <li><a href="">여행</a></li>
          <li><a href="">맛집</a></li>
          <li><a href="">공연/전시</a></li>
          <li><a href="">게임</a></li>
          <li><a href="">스포츠</a></li>
          <li><a href="">웹툰</a></li>
          <li><a href="">컨설팅</a></li>
          <li><a href="">기타</a></li>
        </ul>
      </div>
      <div class="busi_in_box">
        <div class="tit_2">제목</div>
        <ul>
          <li>
            <input
              type="text"
              name=""
              placeholder="제휴할 제목을 적어주세요."
              class="w_max"
            />
          </li>
        </ul>
      </div>
      <div class="busi_in_box">
        <div class="tit_2">내용</div>
        <ul>
          <li class="add">
            <input
              type="text"
              placeholder="제안내용을 첨부해주세요."
              name=""
            /><a href="#" class="t_1">파일찾기</a
            ><a href="#" class="t_2">파일삭제 <font color="red">X</font></a>
          </li>
        </ul>
      </div>
      <div class="busi_term_box">
        <div class="top">
          개인정보 수집 및 이용에 동의합니다.<input type="checkbox" name="" />
        </div>
        <p class="p_top">
          ㈜리뷰앤메이크머니는 제휴를 신청하는 기업 및 개인을 대상으로 아래와
          같이 개인정보를 수집합니다.
        </p>
        <p class="be_1">
          개인정보 수집 항목: 회사명, 담당자명, 이메일, 전화번호(이상 필수),
          회사 홈페이지 주소(선택).
        </p>
        <p class="be_2">
          개인정보 이용 목적: 제휴사 신청 절차를 위한 당사자 확인 및 소통.
        </p>
        <p class="be_3">
          개인정보 보유 및 이용 기간: 제휴사 신청 절차 종료 후 1달간 보유 및
          이용 기간 종료 후 즉시 파기.
        </p>
      </div>
      <div class="busi_term_box">
        <div class="top">
          아래 추가사항을 확인했습니다. <input type="checkbox" name="" />
        </div>
        <p class="be_1 p_top">
          제휴사 신청서 등록 시 '제안자 등록 번호'가 발급되고 신청서에 입력된
          이메일 주소로 발송됩니다.
        </p>
        <p class="be_2">
          검토현황 조회 시 '제안자 등록 번호'와 신청서에 입력된 '전화번호'가
          사용됩니다.
        </p>
        <p class="be_3">
          제휴사 신청 등록 시 발급되는 '제안자 등록 번호' 역시 제휴사 신청 절차
          종료 1달 후 파기됩니다.
        </p>
      </div>
      <div class="busi_btn">
        <a href="#"
          ><img src="@/assets/images/sub/my_move_ico.png" alt="" />제휴사 신청
          보내기</a
        >
      </div>
    </div> -->

    <!-- <div class="tit_2">검토현황 조회<a href="#">∨</a></div>
    <div class="busi_sec_4">
      <div class="busi_in_box">
        <div class="tit_2">등록정보 입력</div>
        <ul>
          <li>
            <input
              type="text"
              name=""
              placeholder="신청서에 입력된 전화번호를 적어주세요."
              class="w_max"
            />
          </li>
          <li>
            <input
              type="text"
              name=""
              placeholder="신청서 접수 후 발급받은 제안자 등록번호를 적어주세요."
              class="w_max"
            />
          </li>
        </ul>
      </div>
      <div class="busi_btn"><a href="#">조회하기</a></div>
    </div> -->
    <div class="tit_2">
      검토현황 조회<router-link
        to=""
        @click.native="$toast.default('준비중 입니다.')"
        >∨</router-link
      >
    </div>
    <div class="tit_2">
      리뷰거래소<router-link
        to=""
        @click.native="$toast.default('준비중 입니다.')"
        >∨</router-link
      >
    </div>
    <!-- <div class="busi_sec_5">
      <div class="txt">
        램은 건강한 리뷰 문화를 위해 리뷰 거래소를 운영합니다. 리뷰 거래소
        운영방침은 아래와 같습니다.
        <p>
          리뷰 거래소는 램에 등록된 제휴사, 판매자, 리뷰거래소 회원, 리뷰어 만(
          18세 이상 만) 이용할 수 있습니다.
        </p>
        <p>
          제휴사, 판매자, 리뷰거래소 회원은 리뷰 서비스와 관련해 리뷰어들에게
          공개 광고를 할 수 있습니다.
        </p>
        <p>
          리뷰어들은 제휴사, 판매자, 리뷰 거래소 회원들에게 리뷰 서비스 관련
          공개 광고를 할 수 있습니다.
        </p>
        <p>
          1:1 리뷰 서비스 거래의 경우, 제휴사, 판매자, 리뷰 거래소 회원이
          리뷰어에게 거래 제안을 하는 것만 가능합니다.
        </p>
        <p>
          리뷰 거래소에서 진행되는 거래는 모두 램의 절차를 따라야 하며, 인프라
          사용료가 발생합니다. 이를 어길 시 당사자들은 경고, 채널 일시 정지,
          채널 영구 정지, 계정 삭제 등을 당할 수 있습니다.
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: this.$route.query.tab || null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.tab) {
        document.querySelector(`#${this.tab}`).scrollIntoView();
      }
    });
  },
  methods: {
    openTab(e, num) {
      let detail = "";
      switch (num) {
        case 1:
          detail = document.querySelector("#tab1_detail");
          if (detail.style.display === "none") {
            e.target.textContent = "∧";
            detail.style.display = "block";
          } else {
            e.target.textContent = "∨";
            detail.style.display = "none";
          }
          break;
        case 2:
          detail = document.querySelector("#tab2_detail");
          if (detail.style.display === "none") {
            e.target.textContent = "∧";
            detail.style.display = "block";
          } else {
            e.target.textContent = "∨";
            detail.style.display = "none";
          }
          break;
      }
    },
  },
};
</script>

<style></style>
